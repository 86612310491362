@import '../../variables';
@media screen and (min-width: 768px) {
    #home-app-container{
        #menu-nav{
            background-color: $primary;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 100;
            margin: 0;
            padding: 10px 0;
            #shopping-cart-badge-container{
                &:hover #shopping-cart-badge, 
                &.added-to-cart #shopping-cart-badge{
                    background-color: $primary !important;
                }
                #shopping-cart-badge{
                    width: 1.2rem;
                    height: 1.2rem;
                    margin-left: -37%;
                    margin-top: 25%;
                    border-radius: 50%;
                    background-color: $secondary !important;
                    color: #fff;
                    font-size: 0.6rem;
                    font-weight: 500;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transform: translate(70%, -100%);
                    transition: .3s;
                    z-index: 11;
                }
                #shopping-cart-list{
                    position: absolute;
                    display: none;
                    padding: 10px 5px;
                    top: -2vh;
                    right: 0;
                    width: 25vw;
                    max-height: 40vh;
                    min-width: 400px;
                    z-index: 10;
                    transform: translate(-3%, 8vh);
                    background-color: rgba(255, 255, 255, 0.98) !important;
                    -webkit-box-shadow: 0px 0px 5px 0px rgba(204,204,204,1);
                    -moz-box-shadow: 0px 0px 5px 0px rgba(204,204,204,1);
                    box-shadow: 0px 0px 5px 0px rgba(204,204,204,1);
                    .shopping-cart-list-item{
                        margin: 0 0 10px 0;
                        padding: 0 0 10px 0;
                        display: block;
                        width: 100%;
                        .shopping-cart-list-image-container,
                        .shopping-cart-list-name,
                        .shopping-cart-list-quantity,
                        .product-remove-button{
                            display: inline-block;
                            vertical-align: middle;
                            
                        }
                        .shopping-cart-list-image-container{
                            width: 4vw;
                            height: 6vw;
                            background-position: center;
                            background-size: 100% auto;
                            background-repeat: no-repeat;
                            margin-right: 10px;
                        }
                        .shopping-cart-list-name{
                            margin: 0;
                            padding: 0;
                            font-size: 12px;
                            width: 40%;
                            letter-spacing: 0.3px;
                            color: $primary !important;
                        }
                        .shopping-cart-list-quantity{
                            padding: 0 10px;
                            border: 2px solid $gray-secondary;
                            font-size: 12px;
                            width: 30%;
                            .product-quantity-input{
                                border: none;
                                padding: 0;
                                font-size: 12px;
                            }
                            i{
                                color: $gray-secondary;
                                margin: 0;
                                padding: 0;
                                font-size: 12px;
                            }
                        }
                        .product-remove-button{
                            font-size: 14px;
                            margin: 0 0 0 3%;
                            padding: 0;
                            cursor: pointer;
                            border: none;
                            background-color: transparent;
                            color: $primary;
                            transition: .3s;
                            color: #000;
                            &:hover{
                                color: $danger;
                            }
                        }
                    }
                }
            }
            #menu-principal{
                margin: 0;
                #menu-social-list{
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: fit-content;
                    width: 35vw;
                    .menu-social-list-item{
                        margin: 0;
                        padding: 0 25px 0 0;
                        display: inline-block;
                        vertical-align: middle;
                        cursor: pointer;
                        transition: .3s;
                        &:hover{
                            transform: scale(1.1);
                        }
                        img{
                            height: 15px;
                        }
                    }
                }
                #menu-busssines-logo{
                    width: 140px;
                    cursor: pointer;
                }
                #menu-search-login-opt{
                    width: 35vw;
                    #menu-search-input-container{
                        background-color: $third;
                        width: fit-content;
                        padding: 3px 10px;
                        border-radius: 26px;
                        #menu-search-input{
                            margin: 0;
                            width: 15vw;
                            max-width: 200px;
                            border: none;
                            border-radius: 26px;
                            //remove border border  on focus
                            &:focus{
                                outline: none;
                            }
                        }
                        .fa-magnifying-glass{
                            color: $primary;
                            margin: 0;
                            padding: 0;
                        }
                    }
                    i{
                        cursor: pointer;
                        transition: .3s;
                        color: $third;
                        font-size: 20px;
                        padding: 0 0 0 15px;
                        &:hover{
                            transform: scale(1.1);
                        }
                    }
                    #menu-shopping-cart-opt{}
                }
            }
            #menu-secondary{
                #menu-opt-list{
                    margin: 0;
                    padding: 1vh 0 0 0;
                    display: block;
                    width: 100%;
                    text-align: center;
                    .menu-opt-list-item{
                        margin: 0;
                        padding: 0 2vw;
                        display: inline-block;
                        vertical-align: middle;
                        cursor: pointer;
                        a,p{
                            color: $third;
                            transition: .3s;
                            border-bottom: 1px solid $primary;
                            font-size: .9em;
                            text-decoration: none;
                            margin: 0;
                            padding: 0;
                        }
                        &:hover a, &:hover p{
                            border-bottom: 1px solid $third;
                            
                        }
                        #brands-list-menu{
                            margin: 0;
                            padding: 4vh 0;
                            width: 100%;
                            background-color: #FFF;
                            position: absolute;
                            left: 0;
                            text-align: center;
                            list-style: none;
                            transform: translateY(2%);
                            display: none;
                            .brand-img-menu{
                                width: 150px;
                                margin: 0 2vw;
                                padding: 0;
                                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
                                border-radius: 50%;
                                transition: .5s;
                                &:hover{
                                    transform: rotate(10deg);
                                }
                            }
                        }
                        #category-container-menu{
                            margin: 0;
                            padding: 4vh 0;
                            width: 100%;
                            background-color: #FFF;
                            position: absolute;
                            left: 0;
                            text-align: center;
                            list-style: none;
                            transform: translateY(2%);
                            display: none;
                            #category-list-menu{
                                list-style: none;
                                margin: 0;
                                padding: 0;
                                display: block;
                                width: 60%;
                                min-width: 500px;
                                text-align: left;
                                .menu-category-list-item{
                                    margin: 0;
                                    padding: 1% 1%;
                                    display: inline-block;
                                    vertical-align: top;
                                    width: 30%;
                                    cursor: pointer;
                                    text-align: center;
                                    transition: .3s;
                                    &:hover{
                                        color: $secondary;
                                    }
                                }
                            
                            }
                        }
                        &.menu-opt-contact{
                            background-color: $secondary !important;
                            border-radius: 20px;
                            color: $third !important;
                            a{
                                text-decoration: none !important;
                                border-bottom: none !important;
                            }
                        }
                    }
                }
            }
            
        }
        
        #footer-container{
            height: 65vh;
            padding: 6vh 10vw;
            .footer-column{
                height: 100%;
                #footer-img{
                    width: 70%;
                    min-width: 300px;
                    height: auto;
                    display: block;
                    margin: 0 auto;
                }
                .footer-list{
                    margin: 0 0 15px 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    list-style: none;
                    .footer-list-item{
                        display: block;
                        color: $gray-secondary;
                        transition: .3s;
                        cursor: pointer;
                        a{
                            text-decoration: none;
                            color: $gray-secondary;
                            transition: .3s;
                            cursor: pointer;
                            &:hover{
                                color: $primary;
                            }
                        }
                        &:hover{
                            color: $primary;
                        }
                        &.footer-list-item-title{
                            font-size: 14px;
                            font-weight: bold;
                            margin: 0 0 7px 0;
                            color: $primary;
                        }
                    }
                }
                #footer-social-list{
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    .footer-social-list-item{
                        margin: 0;
                        padding: 0 10% 0 0;
                        display: inline-block;
                        vertical-align: middle;
                        cursor: pointer;
                        transition: .3s;
                        &:hover{
                            transform: scale(1.1);
                        }
                        img{
                            height: 20px;
                            filter: grayscale(100%) brightness(0%);
                        }
                    }
                }
            }
        }
    }
    #contact-container{
        width: 100%;
        height: 86vh;
        margin: 0;
        padding: 0;
        #close-contact-form{
            display: none;
        }
        &.opened{
            position: fixed;
            z-index: 100;
            top: 0;
            left: 0;
            height: 100dvh;
            #close-contact-form{
                display: block;
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
                font-size: 2em;
                color: #fff;
                transition: .3s;
                &:hover{
                    transform: scale(1.1);
                }
            }
            #contact-form-container{
                top: -105vh;
            }
        }
        .contact-sub-container{
            height: 100%;
            margin: 0;
            padding: 0;
        }
        #contact-schedule-container{
            background-color: $primary;
            color: $third;
            #contact-schedule-sub-container{
                width: 55%;
                padding-right: 3%;
                .info-container{
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    .title-container{
                        margin: 0;
                        padding: 0;
                        display: block;
                        width: 100%;
                        .icon,
                        .title{
                            display: inline-block;
                            vertical-align: middle;
                        }
                        .icon{
                            font-size: 30px;
                        }
                        .title{
                            margin: 0 0 0 10px;
                            padding: 0;
                            font-size: 1.2em;
                        }
                    }
                    .text-container{
                        margin: 15px 0 0 0;
                        padding: 0;
                        display: block;
                        width: 100%;
                        .info{
                            font-weight: 200;
                        }
                    }
                    .extra-information-container{
                        .extra-information-sub-container{
                            padding-left: 7%;
                            margin-bottom: 10px;
                            .extra-information-icon{
                                font-size: 15px;
                            }
                            .extra-information-list{
                                margin: 0 0 0 10px;
                                padding: 0;
                                list-style: none;
                                .extra-information-list-item{
                                    margin: 0 0 1px 0;
                                    padding: 0;
                                    display: block;
                                    width: 100%;
                                    .extra-information-list-item-text{
                                        color: #fff;
                                        text-decoration: none;
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        #contact-form-container{
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 40px;
            width: 450px;
            height: 88%;
            position: relative;
            top: -90vh;
            left: 50%;
            transform: translate(-50%, 13%);
            padding: 4vh 3%;
            #contact-form-title{
                margin: 0;
                padding: 0 0 3vh 0;
                width: 100%;
                text-align: center;
                color: $secondary;
            }
            #contact-form-inputs-container{
                margin: auto 0;
                width: 100%;
                .contact-form-input-container{
                    margin: 0 0 10px 0;
                    width: 100%;
                    .contact-form-input{
                        border: 1px solid $gray-secondary;
                        border-radius: 22px;
                        padding: 6px 20px;
                    
                        //set place holder color
                        &::-webkit-input-placeholder {
                            color: $gray-secondary;
                        }
                    }
                }
            }
            #contact-form-submit{
                margin: 0 auto 0 auto;
                border-radius: 32px !important;
                width: 200px;
                background-color: $secondary;
                border: 1px solid $secondary;
                &:hover{
                    background-color: $primary;
                    border: 1px solid $primary;
                }
            }
        }
    }
    #login-container{
        z-index: 100;
        background-color: #fff;
        width: 600px;
        box-shadow: 0px 3px 6px #00000029;
        padding: 25px;
        text-align: center;
        color: $gray-secondary;
        display: none;
        margin-top: 5vh;
        #login-close-button{
            margin: 0;
            padding: 0;
            display: block;
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
            border: none;
            background-color: transparent;
            font-size: 1.3em;
        }
        #login-image-container{
            margin: 0 auto 2vh auto;
            padding: 40px;
            border-radius: 50%;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            width: min-content;
            display: block;
            #login-image{
                width: 70px;
            }
        }
        #login-title{
            margin: 0 0 2vh;
            padding: 0;
            display: block;
            width: 100%;
            text-align: center;
            letter-spacing: 0.5px;
        }
        .input-group{
            padding: 0 0 2vh 0;
            margin: 0 auto;
            width: 50%;
            letter-spacing: 0.5px;
            span{
                margin: 0;
                padding: 0;
                display: block;
                width: 100%;
                text-align: left;
            }
            .form-control{
                border: none;
                border-bottom: 2px solid $gray-secondary;
                padding: 2px 0;
                border-radius: 0;
            }
        }
        #login-button{
            width: 50%;
            margin: 1vh 0;
            letter-spacing: 0.5px;
        }
        #social-media-login-container{
            margin: 1.5vh 0;
            padding: 0;
            i{
                font-size: 25px;
                transition: .3s;
                //google colors
                &.fa-google{
                    color: #DB4437;
                }
                &:hover{
                    transform: scale(1.1);
                }
            }
        }
        #login-register-forgot-container{
            margin: 0;
            padding: 0;
            display: block;
            width: 100%;
            text-align: center;
            letter-spacing: 0.5px;
            span, a{
                margin: 0;
                padding: 0 1%;
                cursor: pointer;
                transition: .3s;
                text-decoration: none;
                &:hover{
                    color: $secondary !important;
                }
            }
            #login-register{
                font-weight: 600;
                color: #000;
                letter-spacing: 0.5px;
            }
        }
    }
    #fast_action_container{
        margin: 0;
        display: flex;
        position: fixed;
        bottom: 10px;
        right: 10px;
        z-index: 9999;
        gap: 10px;
        i{
            margin: 0;
            padding: 10px;
            border-radius: 50%;
        }
        #body-send-email-opt{
            color: #fff;
            background-color: $secondary;
            transition: .3s;
            cursor: pointer;
            box-shadow: 0px 3px 6px #00000029;
            font-size: 25px;
            &:hover{
                transform: rotate(10deg);
            }
        }
        #whatsapp-container{
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            #whatsapp-message{
                margin: 0;
                padding: 3px  20px 3px 10px;
                background-color: #26D366;
                color: #fff;
                transition: .3s;
                font-size: 12px;
                margin-right: -10px;
                border-radius: 20px;
                
            }
            #body-send-whatsapp-opt{
                color: #fff;
                background-color: #26D366;
                transition: .3s;
                box-shadow: 0px 3px 6px #00000029;
                font-size: 25px;
                &:hover{
                    transform: rotate(10deg);
                }
            }
        }
        
    }
    #whatsapp-form-data{
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.99);
        z-index: 9999;
        gap: 1vh;
        #whatsapp-form-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 90%;
            max-width: 700px;
            gap: 1vh;
            #whatsapp-form-close-button{
                margin: 0;
                padding: 0;
                display: block;
                position: absolute;
                top: 10px;
                right: 10px;
                background-color: transparent;
                cursor: pointer;
                font-size: 2em;
                color: $primary;
                transition: .3s;
                border: none;
                font-weight: bold;
                &:hover{
                    transform: scale(1.1);
                }
            }
            #whatsapp-form-title-container{
                margin: 0 auto;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 90%;
                max-width: 700px;
                gap: 10px;
                #whatsapp-form-icon{
                    font-size: 40px;
                    color: #26D366;
                }
                #whatsapp-form-title{
                    margin: 0;
                    padding: 0;
                    font-size: 1.5em;
                    color: $gray-primary;
                }
            }
            .input-group{
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: start;
                width: 100%;
                gap: 5px;
                label{
                    margin: 0;
                    padding: 0;
                    font-size: 1.2em;
                    color: $gray-primary;
                    width: 100%;
                }
                .form-control{
                    margin: 0;
                    padding: 10px;
                    border: 1px solid $gray-secondary;
                    border-radius: 10px;
                    width: 100%;
                    font-size: 1em;
                    &:focus{
                        outline: none;
                    }
                }
            }
            
        }   
        #whatsapp-form-button{
            background-color: #26D366;
            color: #fff;
            font-size: 1.2em;
            cursor: pointer;
            transition: .3s;
            &:hover{
                background-color: #075E54;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    #home-app-container{
        #menu-nav{
            background-color: $primary;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 100;
            margin: 0;
            padding: 10px 0;
            #burger-menu{
                font-size: 24px;
                cursor: pointer;
                color: $third;
            }
            #shopping-cart-badge-container{
                &:hover #shopping-cart-badge, 
                &.added-to-cart #shopping-cart-badge{
                    background-color: $primary !important;
                }
                #shopping-cart-badge{
                    width: 1.2rem;
                    height: 1.2rem;
                    margin-left: -37%;
                    margin-top: 25%;
                    border-radius: 50%;
                    background-color: $secondary !important;
                    color: #fff;
                    font-size: 0.6rem;
                    font-weight: 500;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transform: translate(70%, -100%);
                    transition: .3s;
                    z-index: 11;
                }
                #shopping-cart-list{
                    display: none !important;
                }
            }
            #menu-principal{
                margin: 0;
                #menu-social-list{
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: fit-content;
                    width: 35vw;
                    .menu-social-list-item{
                        margin: 0;
                        padding: 0 25px 0 0;
                        display: inline-block;
                        vertical-align: middle;
                        cursor: pointer;
                        transition: .3s;
                        &:hover{
                            transform: scale(1.1);
                        }
                        img{
                            height: 15px;
                        }
                    }
                }
                #menu-busssines-logo{
                    width: 140px;
                }
                #menu-search-login-opt{
                    i{
                        cursor: pointer;
                        transition: .3s;
                        color: $third;
                        font-size: 15px;
                        padding: 0 0 0 15px;
                        &:hover{
                            transform: scale(1.1);
                        }
                    }
                }
            }
            #menu-secondary{
                #menu-opt-list{
                    margin: 0;
                    padding: 1vh 0 0 0;
                    display: block;
                    width: 100%;
                    text-align: center;
                    .menu-opt-list-item{
                        margin: 0;
                        padding: 0 2vw;
                        display: inline-block;
                        vertical-align: middle;
                        cursor: pointer;
                        a,p{
                            color: $third;
                            transition: .3s;
                            border-bottom: 1px solid $primary;
                            font-size: .9em;
                            text-decoration: none;
                            margin: 0;
                            padding: 0;
                        }
                        &:hover a, &:hover p{
                            border-bottom: 1px solid $third;
                            
                        }
                        
                    }
                }
            }
            
            #responsive-menu{
                margin: 0;
                padding: 0 10px;
                width: 70%;
                height: 100dvh;
                background-color: #FFF;
                position: fixed;
                display: none;
                top: 0;
                left: -100%;
                z-index: 100;
                -webkit-box-shadow: 0px 0px 5px 0px rgba(153,153,153,1);
                -moz-box-shadow: 0px 0px 5px 0px rgba(153,153,153,1);
                box-shadow: 0px 0px 5px 0px rgba(153,153,153,1);
                #responsive-close-opt{
                    margin: 0;
                    padding: 0;
                    display: block;
                    background-color: transparent;
                    cursor: pointer;
                    position: absolute;
                    top: 1%;
                    right: 3%;
                    border: none;
                    font-size: 1.5em;
                }
                #responsive-header-logo{
                    margin: 4% auto 0 auto;
                    display: block;
                    width: 60%;
                }
                #responsive-ul-nav{
                    margin: 4vh 0 0 0;
                    padding: 0;
                    display: block;
                    list-style: none;
                    text-align: center;
                    max-height: 80vh;
                    .nav-list{
                        margin: 0 0 1vh;
                        padding: 0;
                        display: block;
                        width: 100%;
                        font-size: 1em;
                        font-weight: 400;
                        cursor: pointer;
                        color: $primary;
                        &:hover{
                            color: $secondary;
                        }
                        span, a{
                            font-size: 18px;
                            font-weight: 400;
                            cursor: pointer;
                            color: $primary;
                            text-decoration: none;
                            &:hover{
                                color: $secondary;
                            }
                        }
                        .emergent-data{
                            margin: 0;
                            padding: 0;
                            display: block;
                            width: 100%;
                            .emergent-list{
                                margin: 0;
                                padding: 0;
                                display: block;
                                width: 100%;
                                list-style: none;
                                .emergent-list-category{
                                    text-decoration: none;
                                    font-size: 14px;
                                    margin: 0;
                                    padding: 0;
                                    display: block;
                                    width: 100%;
                                }
                                .emergent-list-item{
                                    margin: 0;
                                    padding: 0;
                                    display: block;
                                    width: 100%;
                                    .emergent-list-sub-category{
                                        margin: 0;
                                        padding: 0;
                                        display: block;
                                        width: 100%;
                                        text-decoration: none;
                                        font-size: 12px;
                                    }
                                    
                                }
                            }
                            
                        }
                    }
                }
            }
        }
        
        #footer-container{
            padding: 6vh 4vh;
            .footer-column{
                height: 100%;
                #footer-img{
                    width: 85%;
                    min-width: 300px;
                    height: auto;
                    display: block;
                    margin: 0 auto;
                }
                #footer-legal-docs-list{
                    .footer-list-item-title{
                        color: $primary;
                    }
                }
                .footer-list{
                    margin: 0 0 15px 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    list-style: none;
                    .footer-list-item{
                        display: block;
                        color: $gray-secondary;
                        transition: .3s;
                        cursor: pointer;
                        a{
                            text-decoration: none;
                            color: $gray-secondary;
                            transition: .3s;
                            cursor: pointer;
                            &:hover{
                                color: $primary;
                            }
                        }
                        &:hover{
                            color: $primary;
                        }
                        &.footer-list-item-title{
                            font-size: 14px;
                            font-weight: bold;
                            margin: 0 0 7px 0;
                            color: $primary;
                        }
                    }
                }
                
                #footer-social-list{
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    .footer-social-list-item{
                        margin: 0;
                        padding: 0 10% 0 0;
                        display: inline-block;
                        vertical-align: middle;
                        cursor: pointer;
                        transition: .3s;
                        &:hover{
                            transform: scale(1.1);
                        }
                        img{
                            height: 20px;
                            filter: grayscale(100%) brightness(0%);
                        }
                    }
                }
            }
        }
    }
    #contact-container{
        width: 100%;
        margin: 0;
        padding: 5vh 0;
        background-color: $primary;
        #close-contact-form{
            display: none;
        }
        &.opened{
            position: fixed;
            z-index: 100;
            top: 0;
            left: 0;
            height: 100dvh;
            background-color: rgba(7, 23, 51,.5);
            /*background blur*/
            backdrop-filter: blur(10px);
            #close-contact-form{
                display: block;
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
                font-size: 2em;
                color: #fff;
                transition: .3s;
                &:hover{
                    transform: scale(1.1);
                }
            }
            #contact-form-container{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 90%;
                
            }
        }
        .contact-sub-container{
            height: 100%;
            margin: 0;
            padding: 0;
        }
        #contact-form-container{
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            width: 80%;
            margin: 0 auto;
            padding: 5% 5vh;
            #contact-form-title{
                margin: 0 0 2vh 0;
                padding: 0;
                width: 100%;
                text-align: center;
                color: $secondary;
                font-size: 1.3rem;
            }
            #contact-form-inputs-container{
                margin: auto 0;
                width: 100%;
                .contact-form-input-container{
                    margin: 0 0 10px 0;
                    width: 100%;
                    .contact-form-input{
                        border: 1px solid $gray-secondary;
                        border-radius: 10px;
                        padding: 5px 20px;
                        font-size: 0.8rem;
                    
                        //set place holder color
                        &::-webkit-input-placeholder {
                            color: $gray-secondary;
                        }
                    }
                }
            }
            #contact-form-submit{
                padding-top: 4px;
                padding-bottom: 4px;
                margin: 0 auto 0 auto;
                border-radius: 32px !important;
                width: 200px;
                background-color: $secondary;
                border: 1px solid $secondary;
                &:hover{
                    background-color: $primary;
                    border: 1px solid $primary;
                }
            }
        }
    }
    
    #login-container{
        z-index: 100;
        background-color: #fff;
        width: 95%;
        box-shadow: 0px 3px 6px #00000029;
        padding: 25px;
        text-align: center;
        color: $gray-secondary;
        display: none;
        margin-top: 5vh;
        #login-close-button{
            margin: 0;
            padding: 0;
            display: block;
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
            border: none;
            background-color: transparent;
            font-size: 1.3em;
        }
        #login-image-container{
            margin: 0 auto 2vh auto;
            padding: 40px;
            border-radius: 50%;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            width: min-content;
            display: block;
            #login-image{
                width: 70px;
            }
        }
        #login-title{
            margin: 0 0 2vh;
            padding: 0;
            display: block;
            width: 100%;
            text-align: center;
            letter-spacing: 0.5px;
        }
        .input-group{
            padding: 0 0 2vh 0;
            margin: 0 auto;
            width: 90%;
            letter-spacing: 0.5px;
            
            span{
                margin: 0;
                padding: 0;
                display: block;
                width: 100%;
                text-align: center;
            }
            .form-control{
                border: none;
                border-bottom: 2px solid $gray-secondary;
                padding: 2px 0;
                border-radius: 0;
                text-align: center;
            }
        }
        #login-button{
            width: 50%;
            margin: 1vh 0;
            letter-spacing: 0.5px;
        }
        #social-media-login-container{
            margin: 1.5vh 0;
            padding: 0;
            i{
                font-size: 25px;
                transition: .3s;
                //google colors
                &.fa-google{
                    color: #DB4437;
                }
                &:hover{
                    transform: scale(1.1);
                }
            }
        }
        #login-register-forgot-container{
            margin: 0;
            padding: 0;
            display: block;
            width: 100%;
            text-align: center;
            letter-spacing: 0.5px;
            span, a{
                margin: 0;
                padding: 0 1%;
                cursor: pointer;
                transition: .3s;
                text-decoration: none;
                &:hover{
                    color: $secondary !important;
                }
            }
            #login-register{
                font-weight: 600;
                color: #000;
                letter-spacing: 0.5px;
            }
        }
    }
    #fast_action_container{
        margin: 0;
        display: flex;
        position: fixed;
        bottom: 10px;
        right: 10px;
        z-index: 9999;
        gap: 10px;
        i{
            margin: 0;
            padding: 15px;
            border-radius: 50%;
        }
        #body-send-email-opt{
            color: #fff;
            background-color: $secondary;
            transition: .3s;
            cursor: pointer;
            box-shadow: 0px 3px 6px #00000029;
            font-size: 25px;
            &:hover{
                transform: rotate(10deg);
            }
        }
        #whatsapp-container{
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            #whatsapp-message{
                display: none;
            }
            #body-send-whatsapp-opt{
                color: #fff;
                background-color: #26D366;
                transition: .3s;
                box-shadow: 0px 3px 6px #00000029;
                font-size: 25px;
                &:hover{
                    transform: rotate(10deg);
                }
            }
        }
    }
    #whatsapp-form-data{
        margin: 0;
        padding: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.99);
        z-index: 9999;
        gap: 1vh;
        #whatsapp-form-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 90%;
            gap: 1vh;
            #whatsapp-form-close-button{
                margin: 0;
                padding: 0;
                display: block;
                position: absolute;
                top: 10px;
                right: 10px;
                background-color: transparent;
                cursor: pointer;
                font-size: 2em;
                color: $primary;
                transition: .3s;
                border: none;
                font-weight: bold;
                &:hover{
                    transform: scale(1.1);
                }
            }
            #whatsapp-form-title-container{
                margin: 0 auto;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 90%;
                max-width: 700px;
                gap: 10px;
                #whatsapp-form-icon{
                    font-size: 40px;
                    color: #26D366;
                }
                #whatsapp-form-title{
                    margin: 0;
                    padding: 0;
                    font-size: 1.5em;
                    color: $gray-primary;
                }
            }
            .input-group{
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: start;
                width: 100%;
                gap: 5px;
                label{
                    margin: 0;
                    padding: 0;
                    font-size: 1.2em;
                    color: $gray-primary;
                    width: 100%;
                }
                .form-control{
                    margin: 0;
                    padding: 10px;
                    border: 1px solid $gray-secondary;
                    border-radius: 10px;
                    width: 100%;
                    font-size: 1em;
                    &:focus{
                        outline: none;
                    }
                }
            }
            
        }   
        #whatsapp-form-button{
            background-color: #26D366;
            color: #fff;
            font-size: 1.2em;
            cursor: pointer;
            transition: .3s;
            &:hover{
                background-color: #075E54;
            }
        }
    }
}
